.orange {
  color: #f16209;
}

/* 
 * ----------------------------------------------------------------------------------------
 * Global Style.
 * ----------------------------------------------------------------------------------------
 */
h4 {
  margin: 0;
}

h5 {
  margin: 0;
  font-weight: bold;
}

p {
  margin: 0;
}

.icon-info {
  display: inline-block;
  background-image: url("../images/icon-set.png");
  background-repeat: no-repeat;
  background-position: 0px -53px;
  width: 40px;
  height: 40px;
}

.icon-info.active {
  background-position: 0px -96px;
}

/*
 * ----------------------------------------------------------------------------------------
 * Helpers.
 * ----------------------------------------------------------------------------------------
 */
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.width100 {
  width: 100%;
}

.width75 {
  width: 75%;
}

.width50 {
  width: 50%;
}

.m-l-r-0 {
  margin-left: 0;
  margin-right: 0;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-30 {
  margin-bottom: 30px  !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-50 {
  margin-bottom: 50px  !important;
}

.m-b-100 {
  margin-bottom: 100px  !important;
}

.m-t-10 {
  margin-top: 10px  !important;
}

.m-t-20 {
  margin-top: 20px  !important;
}

.m-t-30 {
  margin-top: 30px  !important;
}

.m-t-40 {
  margin-top: 30px  !important;
}

.m-t-50 {
  margin-top: 50px  !important;
}

.m-t-100 {
  margin-top: 100px  !important;
}

.seprater-bottom-30 {
  content: "";
  height: 1px;
  display: block;
  margin-bottom: 30px;
}

.seprater-bottom-50 {
  content: "";
  height: 1px;
  display: block;
  margin-bottom: 50px;
}

.seprater-bottom-100 {
  content: "";
  height: 1px;
  display: block;
  margin-bottom: 100px;
}

.border1 {
  border: 1px solid #000;
}

.border2 {
  border: 2px solid #000;
}

.border-0 {
  border: 0;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.devider-90 {
  content: "";
  width: 90%;
  height: 1px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: #ddd;
}

.devider-100 {
  content: "";
  width: 100%;
  height: 1px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: #ddd;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.inline-element {
  display: inline !important;
}

.two-fields {
  font-size: 0;
}

.two-fields .form-group {
  font-size: 12px;
}

.two-fields .form-group {
  width: 50%;
  display: inline-block;
  padding: 0;
}

.two-fields .form-group input {
  width: 100%;
}

.two-fields .form-group select {
  width: 100%;
}

.two-fields .form-group:first-child {
  padding-right: 15px;
}

.two-fields .form-group:last-child {
  padding-left: 15px;
}

.three-fields {
  font-size: 0;
}

.three-fields .form-group {
  font-size: 12px;
}

.three-fields .form-group {
  width: 33.33%;
  display: inline-block;
  padding: 0;
}

.three-fields .form-group input {
  width: 95%;
}

.three-fields .form-group select {
  width: 95%;
}

.three-fields .form-group:last-child input {
  width: 100%;
  padding-left: 15px;
}

.three-fields .form-group:last-child select {
  width: 100%;
  padding-left: 15px;
}

.three-fields .form-group:last-child {
  padding-left: 15px;
}

.three-fields .form-group:first-child {
  padding-right: 15px;
}

.four-fields {
  font-size: 0;
}

.four-fields .single {
  font-size: 14px;
}

.four-fields .form-group {
  font-size: 14px;
}

.four-fields .form-group {
  width: 25%;
  display: inline-block;
  padding: 10px;
}

.four-fields .form-group input {
  width: 95%;
}

.four-fields .form-group select {
  width: 95%;
}

.four-fields .form-group:last-child input {
  width: 100%;
  padding-left: 10px;
}

.four-fields .form-group:last-child select {
  width: 100%;
  padding-left: 10px;
}

.four-fields .form-group:last-child {
  padding-left: 0;
}

.four-fields .form-group:first-child {
  padding-right: 10px;
  padding-left: 0;
}

.five-fields {
  font-size: 0;
}

.five-fields .single {
  font-size: 14px;
}

.five-fields .div {
  font-size: 14px;
}

.five-fields .div {
  width: 20%;
  display: inline-block;
  padding: 10px;
}

.five-fields .div input {
  width: 95%;
}

.five-fields .div select {
  width: 95%;
}

.five-fields .div:last-child input {
  width: 100%;
  padding-left: 10px;
}

.five-fields .div:last-child select {
  width: 100%;
  padding-left: 10px;
}

.five-fields .div:last-child {
  padding-left: 10px;
  padding-right: 0;
}

.five-fields .div:first-child {
  padding-right: 10px;
  padding-left: 0;
}

.grid-0 > [class*='col-'] {
  padding: 0;
}

/* ----------------------------------------------------------------------------------------
 * Global Style.
 * Header styles.
 * Nav styles.
 * Main styles.
 * Sidebar styles.
 * Footer styles.
 * ----------------------------------------------------------------------------------------
 */
/*
 * ----------------------------------------------------------------------------------------
 * Header styles.
 * ----------------------------------------------------------------------------------------
 */
/*
 * ----------------------------------------------------------------------------------------
 * Nav styles.
 * ----------------------------------------------------------------------------------------
 */
.navbar {
  border-radius: 0;
  border: 0;
  margin-bottom: 20px;
}

.navbar-default {
  background-color: #1080db;
  border-color: transparent;
}

.navbar-default .navbar-nav li a {
  background: #1080db;
  color: #fff;
  border-right: 1px solid;
  border-color: #1080db;
}

.navbar-default .navbar-nav li a:hover, .navbar-default .navbar-nav li a:focus, .navbar-default .navbar-nav li a:active {
  background: #0e72c3;
  color: #fff;
}

.navbar-default .navbar-nav li.active a, .navbar-default .navbar-nav li.active:hover a, .navbar-default .navbar-nav li.active:focus a {
  background: #0e72c3;
  color: #fff;
}

.navbar-default .navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
}

.navbar-default .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-default .dropdown-menu {
  background: #0e72c3;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #fff;
  background-color: #0e72c3;
}

.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  border: 0;
}

/*
 * ----------------------------------------------------------------------------------------
 * Main styles.
 * ----------------------------------------------------------------------------------------
 */
/*  ----------------------------------------------------*/
.page-wraper {
  padding-left: 372px;
}

.page-wraper:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .page-wraper {
    padding-left: 0;
  }
}

.page-wraper.full-page {
  padding-left: 0;
}

.page-sidebar {
  margin-left: -372px;
  width: 372px;
  float: left;
  padding: 40px 30px;
}

@media (max-width: 767px) {
  .page-sidebar {
    width: 100%;
    margin-left: 0;
  }
}

.page-content-outer {
  width: 100%;
  float: right;
}

.page-content {
  padding: 40px 30px;
}

.page-content:after {
  content: "";
  display: table;
  clear: both;
}

.page-sidebar-inner {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.page-content-inner {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 30px;
}

.page-content-inner h3 {
  margin: 0;
  font-size: 24px;
}

.sidebar-content-sidebar {
  padding-left: 372px;
  padding-right: 372px;
}

.sidebar-content-sidebar:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .sidebar-content-sidebar {
    padding-left: 0;
    padding-right: 0;
  }
}

.sidebar-content-sidebar .page-sidebar-left {
  margin-left: -372px;
  width: 372px;
  float: left;
  padding: 40px 30px;
}

@media (max-width: 767px) {
  .sidebar-content-sidebar .page-sidebar-left {
    width: 100%;
    margin-left: 0;
    padding: 10px 30px;
  }
}

.sidebar-content-sidebar .page-sidebar-right {
  margin-right: -372px;
  width: 372px;
  float: left;
  padding: 40px 30px;
}

@media (max-width: 767px) {
  .sidebar-content-sidebar .page-sidebar-right {
    width: 100%;
    margin-right: 0;
    padding: 10px 30px;
  }
}

.sidebar-content-sidebar .page-content-outer {
  width: 100%;
  float: left;
}

.sidebar-content-sidebar .page-content {
  padding: 40px 30px;
}

.sidebar-content-sidebar .page-content:after {
  content: "";
  display: table;
  clear: both;
}

.inventory-calendar {
  border: 1px solid #cabdbd;
}

.inventory-calendar thead > tr > th {
  text-align: center;
  border: 1px solid #cabdbd;
  font-size: 12px;
  padding: 4px;
}

.inventory-calendar tbody > tr > td {
  padding: 4px;
  border: 1px solid #cabdbd;
  text-align: center;
}

.inventory-calendar .booked {
  background: #F1BC95;
}

.inventory-calendar .partial {
  background: #E6D52E;
}

.inventory-calendar .available {
  background: #D3F194;
}

.label-calendar {
  display: inline-block;
  width: 80px;
  height: 30px;
  background: #ddd;
  padding: 5px;
  text-align: center;
}

.label-available {
  background: #D3F194;
}

.label-partial {
  background: #E6D52E;
}

.label-booked {
  background: #F1BC95;
}

.max-30 {
  max-width: 30%;
}

@media (max-width: 767px) {
  .max-30 {
    max-width: 50%;
  }
}

.table > tbody > tr.sub-row td:first-child {
  padding-left: 30px;
}

.image-thumbnail {
  max-width: 100px;
}

.normal-table tbody > tr > td {
  vertical-align: middle;
}

.action {
  text-align: center;
}

.action a {
  margin-right: 8px;
  font-size: 14px;
}

/*
 * ----------------------------------------------------------------------------------------
 * Sidebar styles.
 * ----------------------------------------------------------------------------------------
 */
/*
 * ----------------------------------------------------------------------------------------
 * Footer styles.
 * ----------------------------------------------------------------------------------------
 */
/*
 * ----------------------------------------------------------------------------------------
 * Responsive.
 * ----------------------------------------------------------------------------------------
 */
/*
 * ----------------------------------------------------------------------------------------
 * Responsive.
 * ----------------------------------------------------------------------------------------
 */
/*==========  Mobile First Method  ==========*/
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

/*
 * ----------------------------------------------------------------------------------------
 * Plugins Setting.
 * ----------------------------------------------------------------------------------------
 */
/* ----------------------------------------------------*/
/* open  */
/* open hover */
/*default placehoder text */
/*border color and radius*/
.ui.selection.dropdown {
  border: 1px solid #ccc;
  color: #555;
  height: 34px;
  min-height: 34px;
  padding: 9px 34px 6px 11px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.ui.search.dropdown > .text {
  cursor: text;
  font-size: 12px;
}

.ui.dropdown .menu > .item {
  font-size: 12px;
}

/* hover */
.ui.selection.dropdown:hover {
  border: 1px solid #ccc;
}

/* open  */
.ui.selection.active.dropdown {
  border-color: #66afe9;
  outline: 0;
  /*-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);*/
}

.ui.selection.active.dropdown .menu {
  border-color: #66afe9;
  outline: 0;
  /* -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);*/
}

/* open hover */
.ui.selection.active.dropdown:hover {
  border-color: #ccc;
  box-shadow: 0px 2px 3px 0px  #ccc;
}

.ui.selection.active.dropdown .menu:hover {
  border-color: #ccc;
  box-shadow: 0px 2px 3px 0px #ccc;
}

.ui.selection.active.dropdown:hover .menu {
  border-color: #ccc;
  box-shadow: 0px 2px 3px 0px #ccc;
}

/*caret*/
.ui.selection.dropdown > .search.icon, .ui.selection.dropdown > .delete.icon, .ui.selection.dropdown > .dropdown.icon {
  top: 10px;
}

/*lable font*/
.ui.multiple.dropdown > .label {
  font-size: 14px;
  color: blueviolet;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

/*default placehoder text */
.ui.dropdown > .default.text, .ui.default.dropdown > .text {
  color: #9e9e9e !important;
}

/* -----------------------------
-------- form-control label
 ------------------------------*/
