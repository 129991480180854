/*
 * ----------------------------------------------------------------------------------------
 * Main styles.
 * ----------------------------------------------------------------------------------------
 */
 

  // sidebar-content layout
 /*  ----------------------------------------------------*/
$sidebar-width: 372px;
$site-border-color: #ddd; 
// body {
// 	background: #263442;
// }


.page-wraper {
	padding-left: $sidebar-width; 
	@include clearfix;
		@include breakpoint ($xs) {
			padding-left: 0; 
		}

	&.full-page {
		padding-left: 0;
	}

}	

.page-sidebar{
	margin-left: -$sidebar-width; 
	width: $sidebar-width; 
	float: left;
	padding:40px 30px; 
	

		@include breakpoint ($xs) {
			width: 100%;
    		margin-left: 0;
		}
}

.page-content-outer {
	width: 100%;
	float: right; 
}

.page-content {
	padding: 40px 30px; 
	@include clearfix; 
	
}

// End Layout

.page-sidebar-inner {
	background: #fff;
	border: 1px solid $site-border-color; 
	border-radius: 6px;
	
}

.page-content-inner {
	background: #fff;  
	border: 1px solid $site-border-color; 
	border-radius: 6px; 
	 padding: 30px; 
	h3 {margin: 0; font-size: 24px; }
}


//sidebar-content-sidebar 

.sidebar-content-sidebar {
	
	$sidebar-width-left: 372px;
	$sidebar-width-right: 372px;
	$site-border-color: #ddd; 

	padding-left: $sidebar-width-left; 
	padding-right: $sidebar-width-right; 
	@include clearfix;
		@include breakpoint ($xs) {
			padding-left: 0; 
			padding-right: 0; 
		}


	.page-sidebar-left{
		margin-left: -$sidebar-width-left; 
		width: $sidebar-width-left; 
		float: left;
		padding:40px 30px; 
		

			@include breakpoint ($xs) {
				width: 100%;
	    		margin-left: 0; 
	    		padding:10px 30px; 
			}
	}

	.page-sidebar-right{
		margin-right: -$sidebar-width-right; 
		width: $sidebar-width-right; 
		float: left;
		padding:40px 30px; 
		

			@include breakpoint ($xs) {
				width: 100%;
	    		margin-right: 0;
	    		padding:10px 30px; 
			}
	}


	.page-content-outer {
		width: 100%;
		float: left; 
	}

	.page-content {
		padding: 40px 30px; 
		@include clearfix; 
		
	}

	

} //sidebar-content-sidebar // End Layout


.inventory-calendar {
		
		thead>tr>th {
			text-align: center; 
			border: 1px solid #cabdbd;
			font-size: 12px;
			padding: 4px; 
		}

		border: 1px solid #cabdbd;

		tbody>tr>td {
			padding: 4px; 
			border: 1px solid #cabdbd;
			text-align: center; 
		}

	.booked {
		background: #F1BC95; 
	}

	.partial {
		background: #E6D52E; 
	}

	.available{

		background: #D3F194; 
	}
}

.label-calendar {
	display: inline-block;
	width: 80px; 
	height: 30px; 
	background: #ddd;
	padding: 5px; 
	text-align: center; 

}
.label-available{
	background: #D3F194; 
}

.label-partial{
	background: #E6D52E; 
}

.label-booked{
	background: #F1BC95; 
}


.max-30 {
	max-width: 30%;
		@include breakpoint ($xs) {
			max-width: 50%; 
		}
}

.table > tbody> tr.sub-row td {
	&:first-child {
		padding-left: 30px; 
	}
}

.image-thumbnail{
	max-width: 100px; 
}


.normal-table {
	tbody>tr>td {
		vertical-align: middle; 
	}
 
}
.action{ 
	
	text-align: center; 
	a {
		margin-right: 8px; 
		font-size: 14px; 
	}

}