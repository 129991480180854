/*
 * ----------------------------------------------------------------------------------------
 * Plugins Setting.
 * ----------------------------------------------------------------------------------------
 */

 // Semantic Ui setting
 /* ----------------------------------------------------*/

$semantic-border-color: #ccc; 
$semantic-border-color-hover: #ccc;
/* open  */
$semantic-border-color-active:  #66afe9;
/* open hover */
$semantic-border-color-open-hover:  #ccc;
/*default placehoder text */
$semantic-placehoder-color:  #9e9e9e;




/*border color and radius*/
 .ui.selection.dropdown {
   border: 1px solid $semantic-border-color;
   color: #555;
   height: 34px;
   min-height: 34px;
   padding: 9px 34px 6px 11px;
   border-radius: 4px;
   -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
   box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
   -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
   transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
 }


 .ui.search.dropdown > .text {
     cursor: text;
     font-size: 12px;
 }
 .ui.dropdown .menu > .item {
   font-size: 12px;
 }
 /* hover */
 .ui.selection.dropdown:hover {
   border: 1px solid $semantic-border-color-hover;
 }
 
 /* open  */
 .ui.selection.active.dropdown {
     border-color: $semantic-border-color-active;
      outline: 0;
      /*-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);*/
 }

 .ui.selection.active.dropdown .menu {
     border-color: $semantic-border-color-active;
      outline: 0;
     /* -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);*/
 }
 /* open hover */
 .ui.selection.active.dropdown:hover {
     border-color:  $semantic-border-color-open-hover;
     box-shadow: 0px 2px 3px 0px  #ccc;
 }

 .ui.selection.active.dropdown .menu:hover {
     border-color:  #ccc;
     box-shadow: 0px 2px 3px 0px  $semantic-border-color-open-hover;
 }

 
 .ui.selection.active.dropdown:hover .menu {
     border-color:  #ccc;
     box-shadow: 0px 2px 3px 0px  $semantic-border-color-open-hover;
 }

 /*caret*/

 .ui.selection.dropdown > .search.icon, .ui.selection.dropdown > .delete.icon, .ui.selection.dropdown > .dropdown.icon {
   top: 10px;
 }

 /*lable font*/

 .ui.multiple.dropdown > .label { 
 
   font-size: 14px;
   color: blueviolet;

   box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
 }

 /*default placehoder text */
 .ui.dropdown > .default.text, .ui.default.dropdown > .text {
     color: $semantic-placehoder-color !important;
 }