/*
 * ----------------------------------------------------------------------------------------
 * Responsive.
 * ----------------------------------------------------------------------------------------
 */

 /*
 * ----------------------------------------------------------------------------------------
 * Responsive.
 * ----------------------------------------------------------------------------------------
 */

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}



 @media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
	

}

 


@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}




@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}




@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}