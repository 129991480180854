// Bootstrap Breakpoints
$xs:  max-width 767px;

$sm: 768px 991px;

$md: 992px 1199px;

$lg: 1200px;

$orange: #f16209;
.orange {
	color: $orange;
}

